:root {
  --background-color-light: white;
  --background-color-dark: #2b2b2b;
  --text-color-light: black;
  --text-color-dark: white;
}

@media (prefers-color-scheme: dark) {
  :root {
	--background-color: var(--background-color-dark);
	--text-color: var(--text-color-dark);
  }
}

@media (prefers-color-scheme: light) {
  :root {
	--background-color: var(--background-color-light);
	--text-color: var(--text-color-light);
  }
}

.loading-box {
  background-color: var(--background-color);
  padding: 2rem;
}

.loading-image {
  display: block;
  margin: 0 auto;
  width: 150px;
}

.loading-text {
  margin-top: 1rem;
  color: var(--text-color);
}